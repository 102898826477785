import { StaticImage } from "gatsby-plugin-image";
import { useHomepageContent } from '../hooks/useHomepageContent';
import HomeSection from "./HomeSection";

export default function App() {
  const homeContent = useHomepageContent();

  return (
    <main className="border-solid border-2 border-foreground-500">
      {/* Flag chili on waves background */}
      <div id="home" className="w-full h-fit bg-cover bg-[url('../images/background-waves.webp')]">
        <div className="object-scale-down px-24 py-2 xs:px-32 xs:py-4 md:px-64 md:py-8 lg:px-96">
          <StaticImage alt="an octopus with pepper characteristics, a stem, a wrinkled forehead resembling a Carolina Reaper, and small flames shooting out of his tentacles"
            src="../images/logo-flag-standalone.webp"
            placeholder='blurred'
            className="h-fit"
            width={574}
          />
        </div>
      </div>
      
      {/* Inset content sections */}
      <div className="mx-2 sm:mx-4 lg:mx-8">
        {/* Square ECommerce section for top items */}
        <div id="shop">
          <h1>TODO: Store Goes Here</h1>
        </div>
        {/* Dynamic home sections from Contentful */}
        <span id="about"/>
        {
          homeContent.map(doc => (
            <div key={doc.contentful_id}>{HomeSection(doc)}</div>
          ))
        }
      </div>
    </main>
  );
}