import {
    NavigationMenu,
    NavigationMenuList,
    NavigationMenuLink,
    NavigationMenuIndicator
} from "@/components/ui/navigation-menu"
import { StaticImage } from 'gatsby-plugin-image';

export default function Header(){
    return (
        <header className="sticky top-0 z-40 w-full border-b border-border/40 bg-background">
            <div className="container flex h-14 max-w-screen-2xl items-center">
                <NavigationMenu>
                    <NavigationMenuList className="gap-4">
                        <NavigationMenuLink href="#home" className='hidden md:inline'>
                            <StaticImage
                                src='../images/icon.webp'
                                alt="The Bosun's Brew Chili-pus Logo"
                                class='h-10 w-10'
                                width={64}
                            />
                        </NavigationMenuLink>
                        <NavigationMenuLink href="#home" active={true} className="gap-2">
                            Home
                        </NavigationMenuLink>
                        <NavigationMenuLink href="#shop">
                            Shop
                        </NavigationMenuLink>
                        <NavigationMenuLink className="text-center" href="#about">
                            About Us
                        </NavigationMenuLink>
                        <NavigationMenuLink href="#contact">
                            Contact
                        </NavigationMenuLink>
                        <NavigationMenuIndicator/>
                    </NavigationMenuList>
                </NavigationMenu>
            </div>
        </header>
    )
};