import { Button } from "./ui/button";
import { CookieConsentButton } from "./CookieConsentMessage";
import {FaFacebook, FaInstagram} from 'react-icons/fa'

export default function Footer() {
    return (
        // FIXME: too much padding when page content is less than 1 vertical screen
        <div className="bg-footer mt-auto text-background">
            <div className='flex flex-row'>
                <div className='md:container md:mx-auto p-2 text-base grow'>
                    <div className='flex flex-col max-w-36'>
                        <h3 className='font-bold text-left'>Find us on socials!</h3>
                        <Button asChild variant='ghost' className='justify-start'>
                            <a href='https://www.facebook.com/BosunsBrew'>
                                <FaFacebook aria-hidden className='w-5 h-5 mr-2' />Facebook
                            </a>
                        </Button>
                        <Button asChild variant='ghost' className='justify-start'>
                            <a href='https://www.instagram.com/BosunsBrew'>
                                <FaInstagram aria-hidden className='w-5 h-5 mr-2' />Instagram
                            </a>
                        </Button>
                    </div>
                </div>
                <div className='shrink mt-auto mx-2 p-2'>
                    <CookieConsentButton />
                </div>
            </div>
        </div>
    );
}