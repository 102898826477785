import { graphql, useStaticQuery } from 'gatsby'
import { useLocation } from '@reach/router';

export type webSeo = {
    contentful_id: string;
    title: string;
    description: string;
    ogTitle?: string;
    ogDescription?: string;
    ogImage?: string;
    ogType?: string;
    ogLocale?: string;
    page: string;
    noindex: boolean;
}

const ERROR_ID = 'error';

const ERROR_SEO: webSeo = {
    contentful_id: ERROR_ID,
    title: 'Untitled Page',
    description: 'Search Engine metadata is unavailable.',
    ogType: 'website',
    ogLocale: 'en_US',
    page: '/',
    noindex: true
};

export default function useWebSeo(): webSeo {
    const location = useLocation();

    const content = useStaticQuery(graphql`
        query {
            allContentfulWebSeo {
                nodes {
                    contentful_id
                    title
                    description
                    ogTitle
                    ogDescription
                    ogImage
                    ogType
                    ogLocale
                    page
                    noindex
                }
            }
        }
    `);

    const contentfulSeo: webSeo[] = content.allContentfulWebSeo.nodes;

    const defaultSeo:webSeo|undefined = contentfulSeo.find((value) => {
        return value.page === '/';
    });
    
    if (undefined === defaultSeo) {
        console.error('Could not load SEO from contentful, using NO SEO and disabling indexing');
    }

    return contentfulSeo.find((value) => {
        return value.page === location.pathname;
    }) || defaultSeo || ERROR_SEO;
}