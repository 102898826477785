import {useStaticQuery, graphql } from 'gatsby'

export type homeSectionData = {
    title: string;
    body: {
        raw: string;
    };
    callToActionTitles: string[];
    callToActionUrl: string[];
    callToActionClassName: string[];
    contentful_id: string;
}

export const useHomepageContent = () : homeSectionData[] => {
    const content = useStaticQuery(graphql`
        query {
            allContentfulHomepageSection(sort: {index: ASC}) {
                nodes {
                    title
                    body {
                        raw
                    }
                    callToActionTitles
                    callToActionUrl
                    contentful_id
                }
            }
        }
    `);
    
    return content.allContentfulHomepageSection.nodes;
};