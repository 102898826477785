import { Link } from "gatsby";
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { homeSectionData } from '../hooks/useHomepageContent';

const defaultButtonClassName="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"

export default function HomeSection(props : homeSectionData) {
    return (
        <div>
            <h1 className="text-4xl my-2">{props.title}</h1>
            <div className="text-lg">{renderRichText(props.body)}</div>
            <div className="flex justify-end gap-4 mb-2">
                {props.callToActionTitles.map((item, index) => {
                    const contentfulStyles = props.callToActionClassName && props.callToActionClassName[index];

                    const buttonClass = contentfulStyles ? contentfulStyles : defaultButtonClassName;

                    const buttonTarget = props.callToActionUrl[index];
                    return (
                        <Link key={props.contentful_id + index} to={buttonTarget} className={buttonClass}>{ item }</Link>
                    )
                })}
            </div>
        </div>
    )
}