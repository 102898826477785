import useWebSeo from '../hooks/useContentfulWebSeo'

//#region meta description
type metaDescriptionProps = {
    description?: string;
}

function MetaDescription({ description }: metaDescriptionProps){
    if (null !== description) {
        return <meta name='description' content={ description } />
    } else {
        return null;
    }
}
//#endregion

//#region noindex
type noIndexProps = {
    addNoIndex: boolean;
}

function NoIndex({ addNoIndex }: noIndexProps){
    if (addNoIndex) {
        return <meta name='robots' content='noindex'/>
    } else {
        return null;
    }
}
//#endregion

//#region OpenGraph
type ogTitleProps = {
    title?: string;
}

function OpenGraphTitle({ title }: ogTitleProps) {
    if (null != title) {
        return <meta property='og:title' content={title} />;
    } else {
        return null;
    }
}

type ogDescriptionProps = {
    description?: string;
}

function OpenGraphDescription({ description }: ogDescriptionProps) {
    if (null != description) {
        return <meta property='og:description' content={description} />;
    } else {
        return null;
    }
}

type ogImageProps = {
    image?: string;
}

function OpenGraphImage({ image }: ogImageProps) {
    if (null != image) {
        return <meta property='og:image' content={image} />;
    } else {
        return null;
    }
}

type ogTypeProps = {
    type?: string;
}

function OpenGraphType({ type }: ogTypeProps) {
    if (null != type) {
        return <meta property='og:type' content={type} />;
    } else {
        return null;
    }
}

type ogLocaleProps = {
    locale?: string;
}

function OpenGraphLocale({ locale }: ogLocaleProps) {
    if (null != locale && locale !== 'en_US') {
        return <meta property='og:locale' content={locale} />;
    } else {
        return null;
    }
}
//#endregion

export default function SEO() {
    const pageSeo = useWebSeo();

    const { title, description, ogTitle, ogDescription, ogImage, ogType, ogLocale, noindex } = pageSeo;

    //add noindex tags if requested, but ALWAYS add them for the development site.
    const addNoIndex = noindex || Boolean(process.env.DEVELOPMENT);

    return (<>
        <title>{title}</title>
        <MetaDescription description={description}/>
        <OpenGraphTitle title={ogTitle} />
        <OpenGraphDescription description={ogDescription} />
        <OpenGraphImage image={ogImage} />
        <OpenGraphType type={ogType} />
        <OpenGraphLocale locale={ogLocale}/>
        <NoIndex addNoIndex={addNoIndex}/>
    </>);
}