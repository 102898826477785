import type { HeadFC, PageProps } from "gatsby";
import Header from '@/components/Header';
import App from "@/components/App";
import Footer from "@/components/Footer";
import SEO from "@/components/SEO";

const IndexPage: React.FC<PageProps> = () => {
  return <main className="h-screen">
    <Header/>
    <div className='flex flex-col gap-2 md:gap-4 min-h-full'>
      <div className="md:container md:mx-auto">
        <App/>
      </div>
      <Footer/>
    </div>
  </main>
}

export default IndexPage

export const Head: HeadFC = () => (
  <>
    <SEO />
    <html lang='en' />
    {/* <link rel='preload'/> */}
  </>
);

//TODO: document Render.com deploy process